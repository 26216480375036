import { template as template_5684f5635ddc4e1b92e4e0ce606f5f14 } from "@ember/template-compiler";
const FKLabel = template_5684f5635ddc4e1b92e4e0ce606f5f14(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
