import { template as template_158df79b5cc940b6b9db99f24b19ad09 } from "@ember/template-compiler";
const SidebarSectionMessage = template_158df79b5cc940b6b9db99f24b19ad09(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
